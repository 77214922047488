import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './PricingPage.scss';
import {Helmet} from 'react-helmet';

import {EXTERNAL_ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';
import {eorCard, otherSpecializationCards, pricingCard} from './pricing-helpers';
import {pricingData, subscriptionOptionsPricing} from '../../utils/arrayData/PricingData';

export default function PricingPage() {
	return (
		<div>
			<Helmet>
				<title>Pricing | Permworks</title>
				<meta name="description" content="Learn about our pricing. Discover cost-effective solutions for your hiring needs on Permworks." />
			</Helmet>
			<Navbar2 />
			<div className="pricing-container mb-20">
				<h1>Pricing</h1>
				<h2 style={{color: permColors.darkCharcoal}} className="mb-2">
					Our Job Posting Solutions
				</h2>
				<p className="tag-line" style={{color: permColors.charcoal}}>
					At Permworks, we offer flexible subscription plans to match every recruitment need.
				</p>
				{pricingCard(subscriptionOptionsPricing)}
				{eorCard()}

				<div className="p-4 bg-white rounded-lg flex flex-col gap-3 shadow-sm w-fit mx-auto border border-gray-200 mt-10">
					<h3 style={{color: permColors.charcoal}} className="font-semibold mb-2 mt-10">
						Enterprise
					</h3>
					<p className="enterprise-text mb-3">For large scale recruitment and compliance work, the Enterprise plan helps you scale your business quickly with expert tailored services. </p>
					<a href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT} rel="noreferrer" target="_blank" className="w-fit mx-auto">
						<button className="buttonNavy">Contact sales</button>
					</a>
				</div>
				<h3 style={{color: permColors.charcoal}} className="font-semibold mb-3 mt-32">
					Our Other Specializations
				</h3>
				{otherSpecializationCards(pricingData)}
			</div>
			<Footer backgroundColor={permColors.backgroundGrey} />
		</div>
	);
}
