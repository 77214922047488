export function daysAgo(dateString) {
	const date = new Date(dateString);
	const now = new Date();
	const diffInMilliseconds = now - date;
	const days = diffInMilliseconds / (1000 * 60 * 60 * 24);
	return Math.floor(days);
}

// create a function that takes a date like yyyy-mm-dd and returns 23rd April 2020

export function formatDate(dateString) {
	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const ordinalSuffix = (day) => {
		if (day % 10 === 1 && day !== 11) {
			return day + 'st';
		} else if (day % 10 === 2 && day !== 12) {
			return day + 'nd';
		} else if (day % 10 === 3 && day !== 13) {
			return day + 'rd';
		} else {
			return day + 'th';
		}
	};

	const date = new Date(dateString);
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return `${ordinalSuffix(day)} ${monthNames[monthIndex]} ${year}`;
}

// create a function that takes this date:2023-04-12T11:14:45.851691Z and returns 23rd April 2020

export function formatDate2(dateString) {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const ordinal = (n) => {
		const s = ['th', 'st', 'nd', 'rd'];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	const date = new Date(dateString);
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();

	return `${ordinal(day)} ${month} ${year}`;
}

export function formatDateSevenDaysLater(dateString) {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const ordinal = (n) => {
		const s = ['th', 'st', 'nd', 'rd'];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	const date = new Date(dateString);
	date.setDate(date.getDate() + 7); // Add 7 days
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();

	return `${ordinal(day)} ${month} ${year}`;
}

export function formatDateThirtyDaysLater(dateString) {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const ordinal = (n) => {
		const s = ['th', 'st', 'nd', 'rd'];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	const date = new Date(dateString);
	date.setDate(date.getDate() + 30); // Add 30 days
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();

	return `${ordinal(day)} ${month} ${year}`;
}

export const formatDateAndTime = (dateString) => {
	const date = new Date(dateString);
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const month = monthNames[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = ('0' + date.getMinutes()).slice(-2); // Pad with 0 if needed

	return `${month} ${day}, ${year} ${hours}:${minutes}${hours >= 12 ? 'pm' : 'am'}`;
};

export const calendarDateFormatter = (dateString) => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
};
