import React, {useEffect, useState} from 'react';
import './DashboardComponent.scss';
import {stripeBillingHistory} from '../../api/stripeApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Input, Table} from 'antd';
import {ContentCopy} from '@mui/icons-material';
import GoogleSnackbar from '../Snackbars/GoogleSnackbar';
import {formatStripeAmount} from '../../utils/TextAlterations';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {filterByAllBillingHistory} from '../../utils/filters';
import {openNotification} from './EOR/Team';
import {billingHistoryAtom, billingHistoryTriggeredAtom} from '../../utils/atoms/dashboardAtoms';
import {useAtom} from 'jotai';

interface BillingHistoryEntry {
	charge_id: string;
	amount: number;
	created: number;
	job?: {
		title: string;
		company_name: string;
		id: string;
	};
}

export default function BillingHistory() {
	const [loading, setLoading] = useState<boolean>(false);
	const [billingHistory, setBillingHistory] = useAtom(billingHistoryAtom);
	const [billingHistoryTriggered, setBillingHistoryTriggered] = useAtom(billingHistoryTriggeredAtom);
	const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
	const [dateOrder, setDateOrder] = useState<'desc' | 'asc'>('desc');
	const [searchText, setSearchText] = useState<string>('');

	async function fetchBillingHistory() {
		setLoading(true);
		const res = await stripeBillingHistory(dateOrder);
		if (res === false) {
			openNotification('Error', 'Failed to fetch billing history', 'error');
			return setLoading(false);
		}
		setBillingHistory(res);
		setLoading(false);
	}

	useEffect(() => {
		if (!billingHistoryTriggered) {
			fetchBillingHistory();
			setBillingHistoryTriggered(true);
		}
	}, [dateOrder]);

	const copyToClipboard = async (text: string, variable: string) => {
		try {
			await navigator.clipboard.writeText(text);
			setSnackbarMessage(`Copied ${variable} to clipboard`);
			setSnackbarSeverity('success');
			setSnackbarOpen(true);
		} catch (err) {
			console.error('Failed to copy: ', err);
			setSnackbarMessage('Failed to copy');
			setSnackbarSeverity('error');
			setSnackbarOpen(true);
		}
	};

	const toggleDateOrder = () => {
		setDateOrder(dateOrder === 'desc' ? 'asc' : 'desc');
	};

	const columns = [
		{
			title: 'Charge ID',
			dataIndex: 'charge_id',
			key: 'charge_id',
			render: (text: string) => (
				<div className="flex items-center gap-2 justify-between">
					{text}
					<ContentCopy onClick={() => copyToClipboard(text, 'Charge ID')} style={{cursor: 'pointer', fontSize: '1.2em'}} />
				</div>
			),
		},
		{
			title: 'Amount (USD)',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: number) => formatStripeAmount(amount),
		},
		{
			title: (
				<div className="cursor-pointer flex items-center gap-2 justify-between hover:opacity-80" onClick={toggleDateOrder}>
					Created {dateOrder === 'desc' ? <ArrowDownwardIcon style={{fontSize: '1.2em'}} /> : <ArrowUpwardIcon style={{fontSize: '1.2em'}} />}
				</div>
			),
			dataIndex: 'created',
			key: 'created',
			render: (timestamp: number) => new Date(timestamp * 1000).toLocaleDateString(),
		},
		{
			title: 'Job Title',
			dataIndex: ['job', 'title'],
			key: 'jobTitle',
			render: (title: string) => title || 'N/A',
		},
		{
			title: 'Company Name',
			dataIndex: ['job', 'company_name'],
			key: 'companyName',
			render: (name: string) => name || 'N/A',
		},
		{
			title: 'Job ID',
			dataIndex: ['job', 'id'],
			key: 'jobId',
			render: (id: string) =>
				id ? (
					<div className="flex items-center justify-between">
						{id}
						<ContentCopy onClick={() => copyToClipboard(id, 'Job ID')} style={{cursor: 'pointer', fontSize: '1.2em'}} />
					</div>
				) : (
					'N/A'
				),
		},
	];

	if (loading) return <LoadingPartial />;

	const filteredBillingHistory = billingHistory?.filter(filterByAllBillingHistory(searchText));

	return (
		<div className="dashboardComponent">
			<div className="header">
				<h2>Billing history</h2>
			</div>
			<div className="outerContainer">
				<div className="innerContainer">
					<Input placeholder="Search by any field" className="mb-2 w-[250px] mobile-width-100" onChange={(e) => setSearchText(e.target.value)} />
					<Table dataSource={filteredBillingHistory} columns={columns} rowKey="charge_id" scroll={{x: true}} pagination={{pageSize: 10}} />
				</div>
			</div>
			<GoogleSnackbar snackbarOpen={snackbarOpen} snackbarMessage={snackbarMessage} snackbarSeverity={snackbarSeverity} handleClose={() => setSnackbarOpen(false)} />
		</div>
	);
}
