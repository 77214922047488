export const permColors = {
	white: '#fffffe',
	whiteless: '#fffffe90',
	navy: '#094067',
	navyless: '#09406790',
	darkBlue: '#003366',
	blue: '#3da9fc',
	deepBlue: '#082e6e',
	lightBlue: '#d8eefe',
	lightBlueless: '#d8eefecd',
	grey: '#5f6c7b',
	red: '#ef4565',
	backgroundGrey: '#f5f5f5',
	backgroundGreyBlue: '#f8fafc',
	charcoal: '#3e4149',
	linkBlue: '#0077b6',
	darkCharcoal: '#2b2d33',
	backgroundLightCream: '#fafaf6',
};

export const tailwindPermColors = {
	white: 'text-[#fffffe]',
	whiteless: 'text-[#fffffe90]',
	navy: 'text-[#094067]',
	navyless: 'text-[#09406790]',
	darkBlue: 'text-[#003366]',
	blue: 'text-[#3da9fc]',
	deepBlue: 'text-[#082e6e]',
	lightBlue: 'text-[#d8eefe]',
	lightBlueless: 'text-[#d8eefecd]',
	grey: 'text-[#5f6c7b]',
	red: 'text-[#ef4565]',
	backgroundGrey: 'bg-[#f5f5f5]',
	charcoal: 'text-[#3e4149]',
	linkBlue: 'text-[#0077b6]',
	darkCharcoal: 'text-[#2b2d33]',
	backgroundLightCream: 'bg-[#fafaf6]',
};
