import axios from 'axios';
import {mainUrl} from './Urls';
import {messaging} from '../firebase';
import {setFCMApi} from './profileApi';
import {getToken} from 'firebase/messaging';
import api from './interceptor';

export async function getNotificationsApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/notifications/`, config);
		return response.data;
	} catch (error) {
		return false;
	}
}

export function saveFCMTokenApi() {
	getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
		.then((currentToken) => {
			if (currentToken) {
				setFCMApi(currentToken).then((res) => {
					console.log('FCM token generated');
				});
			} else {
				console.log('No registration token available. Request permission to generate one.');
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
		});
}

export function markAllAsReadApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = api.patch(`${mainUrl}/auth/notifications/mark-all-as-read/`, {}, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
