import {useState, useEffect, useContext} from 'react';
import './Dashboard.scss';
import {useLocation} from 'react-router-dom';
import {FullLogo, LightFullLogoSmall} from '../../components/Logos/Logo';
import Inbox, {AvatarWithLoading} from '../../components/Dashboard/Inbox';
import BillingHistory from '../../components/Dashboard/BillingHistory';
import Subscriptions from '../../components/Dashboard/Subscriptions';
import Payments from '../../components/Dashboard/Payments';
import EmployerJob from '../../components/Dashboard/EmployerJob';
import Hamburger from 'hamburger-react';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import AppliedJobs from '../../components/Dashboard/AppliedJobs';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SavedJobs from '../../components/Dashboard/SavedJobs/SavedJobs';
import Notifications from '../../components/Dashboard/Notifications/Notifications';
import Badge from '@mui/material/Badge';
import {markAllAsReadApi} from '../../api/notificationsApi';
import {capitalize} from '@mui/material';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import {ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';
import {AccessTime, AccountBalance, Close, CurrencyExchangeOutlined, LogoutOutlined, OpenInNew, PeopleAlt, PersonAddAlt1, WorkOffOutlined} from '@mui/icons-material';
import {isEmployer, isJobSeeker} from '../../utils/variables/userVariables';
import ClosedJobs from '../../components/Dashboard/ClosedJobs';
import Team from '../../components/Dashboard/EOR/Team';
import TimeOff from '../../components/Dashboard/EOR/TimeOff';
import Onboarding from '../../components/Dashboard/EOR/Onboarding';
import {useAtom} from 'jotai';
import {selectedAtom, teamsAtom} from '../../utils/atoms';
import {fetchEmployees} from '../../api/eor-api/teams-api';
import {DashboardOutlined} from '@ant-design/icons';

export default function Dashboard() {
	const reSize = 1000;
	const {state} = useLocation();
	const [selected, setSelected] = useAtom(selectedAtom);
	const [isOpen, setOpen] = useState(true);
	const [menu, setMenu] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= reSize);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const navigate = useNavigate();
	const [notifications] = useContext(NotificationContext);
	const [unreadNotifications, setUnreadNotifications] = useState();
	const [show, setShow] = useState(false);
	const [live] = useState(false);
	const [teams, setTeams] = useAtom(teamsAtom);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				resolve();
			});
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	useState(() => {
		if (currentUser?.profile) {
			setUnreadNotifications(notifications?.filter((notif) => notif.read === false).length);
		}
	}, []);

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = () => {
		setShow(true);
		if (isMobile) setMenu(false);
	};

	function getStoredSelected() {
		return sessionStorage.getItem('selected') || 'inbox';
	}

	function menuSelector(menuName) {
		setSelected(menuName);
		sessionStorage.setItem('selected', menuName);
		console.log(menuName);

		if (menuName === 'notifications') {
			try {
				markAllAsReadApi();
				setUnreadNotifications(0);
			} catch (error) {
				return;
			}
		}

		if (isMobile) {
			handleClose();
		}
	}

	const employerMenuItems = [
		{name: 'Inbox', key: 'inbox', icon: <InboxOutlinedIcon className="key-icon" />},
		{name: 'My Open Jobs', key: 'jobs', icon: <WorkOutlineIcon className="key-icon" />},
		{name: 'My Closed Jobs', key: 'closedJobs', icon: <WorkOffOutlined className="key-icon" />},
		{name: 'Payments', key: 'payments', icon: <PaymentOutlinedIcon className="key-icon" />},
		{name: 'Subscriptions', key: 'subscriptions', icon: <SubscriptionsOutlinedIcon className="key-icon" />},
		{name: 'Billing History', key: 'billingHistory', icon: <AccountBalance className="key-icon" />},
		{name: 'Notifications', key: 'notifications', icon: <NotificationsOutlinedIcon className="key-icon" />},
	];

	const jobSeekerMenuItems = [
		{name: 'Inbox', key: 'inbox', icon: <InboxOutlinedIcon className="key-icon" />},
		{name: 'Applied Jobs', key: 'appliedJobs', icon: <WorkOutlineIcon className="key-icon" />},
		{name: 'Saved Jobs', key: 'savedJobs', icon: <WorkOutlineIcon className="key-icon" />},
		{name: 'Notifications', key: 'notifications', icon: <NotificationsOutlinedIcon className="key-icon" />},
	];

	const eorMenuItems = [
		{name: 'Onboarding', key: 'onboarding', icon: <PersonAddAlt1 className="key-icon" />},
		{name: 'Team', key: 'team', icon: <PeopleAlt className="key-icon" />},
		{name: 'Time off', key: 'timeOff', icon: <AccessTime className="key-icon" />},
		{name: 'Payroll', key: 'payroll', icon: <CurrencyExchangeOutlined className="key-icon" />},
	];

	async function getTeams() {
		const response = await fetchEmployees();
		setTeams(response);
	}

	useEffect(() => {
		if (selected === 'team' || selected === 'timeOff' || selected === 'onboarding' || selected === 'payroll') {
			if (teams?.length === 0) {
				getTeams();
			}
		}
	}, [selected]);

	useEffect(() => {
		if (state && state.from === 'employerLink') {
			setSelected('jobs');
		}
		if (state && state.from === 'billingMethod') {
			setSelected('payments');
		}
	}, [state]);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= reSize);
			setOpen(window.innerWidth > reSize ? true : isOpen);
			setMenu(window.innerWidth > reSize ? true : false);
		}
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isOpen]);

	function menuItemsDisplay() {
		return (
			<div className="innerMenu">
				<ul>
					{isEmployer(currentUser) && (
						<>
							<li className="tab-header">Job Admin</li>
							{employerMenuItems.map((item) => (
								<>
									<li className={`tab ${selected === item.key && 'selected'}`} onClick={() => menuSelector(item.key)} key={item.key}>
										{item.icon} {item.name}
									</li>
									{selected === 'jobs' && item.key === 'jobs' && (
										<>
											<a href={ROUTES.POST_JOB} className="postJob  tab " target="_blank" rel="noreferrer">
												<li className="pl-8 flex items-center gap-2">
													<OpenInNew style={{fontSize: '1.3em'}} />
													Post a job
												</li>
											</a>
											<a href={ROUTES.BROWSE_JOBS} className="postJob  tab " target="_blank" rel="noreferrer">
												<li className="pl-8 flex items-center gap-2">
													<OpenInNew style={{fontSize: '1.3em'}} />
													Browse All Jobs
												</li>
											</a>
										</>
									)}
								</>
							))}
						</>
					)}
					{isEmployer(currentUser) && live && (
						<>
							<hr style={{color: permColors.backgroundGrey}} className="mx-4 my-3 opacity-10" />
							<li className="tab-header">Administration</li>
							{eorMenuItems.map((item) => (
								<li className={`tab ${selected === item.key ? 'selected' : ''}`} onClick={() => menuSelector(item.key)} key={item.key}>
									{item.icon} {item.name}
								</li>
							))}
						</>
					)}
					{isJobSeeker(currentUser) && (
						<>
							<li className="tab-header">Job Admin</li>
							{jobSeekerMenuItems.map((item) => (
								<>
									<li className={`tab ${selected === item.key && 'selected'}`} onClick={() => menuSelector(item.key)} key={item.key}>
										{item.icon}
										{item.name === 'Notifications' ? (
											<>
												{item.name}
												<Badge sx={{marginLeft: '20px'}} badgeContent={unreadNotifications} color="error"></Badge>
											</>
										) : (
											item.name
										)}
									</li>
									{selected === 'appliedJobs' && item.key === 'appliedJobs' && (
										<a href={ROUTES.BROWSE_JOBS} className="postJob  tab " target="_blank" rel="noreferrer">
											<li className="pl-8 flex items-center gap-2">
												<OpenInNew style={{fontSize: '1.3em'}} />
												Browse All Jobs
											</li>
										</a>
									)}
								</>
							))}
						</>
					)}
				</ul>
				<ul>
					<hr style={{color: permColors.backgroundGrey}} className="mx-4 my-4 opacity-10" />
					<li className="tab profile" onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
						<AvatarWithLoading
							alt={`${currentUser?.profile?.first_name ?? 'Unknown'} ${currentUser?.profile?.last_name ?? 'Applicant'}`}
							src={currentUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
						/>
						<div className="text">
							<p className="name">{capitalize(currentUser?.profile?.first_name) + ' ' + capitalize(currentUser?.profile?.last_name)}</p>
							<p className="email">{currentUser?.email}</p>
						</div>
					</li>
					<li className="tab profile" onClick={logout}>
						<LogoutOutlined style={{fontSize: '1.3em'}} /> Logout
					</li>
					<a href={ROUTES.EOR_DASHBOARD}>
						<li className="tab profile text-white">
							<DashboardOutlined style={{fontSize: '1.3em'}} /> EOR Dashboard
						</li>
					</a>
				</ul>
			</div>
		);
	}

	return (
		<div className="nav-holder">
			<div className="mainContainerLight">
				<Helmet>
					<title>Dashboard | Permworks</title>
					<meta name="description" content="Your personal dashboard. Manage your jobs, applications, and profile." />
				</Helmet>
				<div className="outerMenu">
					<div className={'menu' + (isMobile ? ' mobile-menu' : '')}>
						{isMobile ? (
							<div className="logoHolder ">
								<li onClick={handleShow} className="hamburger2">
									<Hamburger size={26} toggled={show} toggle={setOpen} />
								</li>
								<FullLogo />
							</div>
						) : (
							<div className="logoHolderDesktop">
								<li onClick={handleShow} className="hamburger2">
									<Hamburger size={26} toggled={show} toggle={setOpen} />
								</li>
								<LightFullLogoSmall />
							</div>
						)}

						{menu && menuItemsDisplay()}
					</div>
				</div>
				<div className="content">
					{selected === 'inbox' && <Inbox />}
					{selected === 'payments' && isEmployer(currentUser) ? <Payments /> : null}
					{selected === 'subscriptions' && <Subscriptions />}
					{selected === 'billingHistory' && <BillingHistory />}
					{selected === 'notifications' && <Notifications />}
					{selected === 'savedJobs' && isJobSeeker(currentUser) ? <SavedJobs /> : null}
					{selected === 'jobs' && isEmployer(currentUser) ? <EmployerJob /> : null}
					{selected === 'closedJobs' && isEmployer(currentUser) ? <ClosedJobs /> : null}
					{selected === 'appliedJobs' && isJobSeeker(currentUser) ? <AppliedJobs /> : null}
					{selected === 'team' && <Team setMenuToTeam={menuSelector} />}
					{selected === 'timeOff' && <TimeOff />}
					{selected === 'onboarding' && <Onboarding setMenuToTeam={menuSelector} />}
				</div>
				<Offcanvas show={show} onHide={handleClose}>
					<Offcanvas.Header style={{backgroundColor: permColors.darkCharcoal}}>
						<Offcanvas.Title style={{paddingLeft: '5px'}} className="flex gap-2 justify-between items-center w-full">
							<LightFullLogoSmall />
							<Close onClick={handleClose} className="button-modal-close" />
						</Offcanvas.Title>
					</Offcanvas.Header>
					{menuItemsDisplay()}
				</Offcanvas>
			</div>
		</div>
	);
}
