import React, {Fragment, useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import Hamburger from 'hamburger-react';
import './Navbar2.scss';
import {useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {FullLogo} from '../Logos/Logo';
import Loading from '../Loaders/Loading';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import {formatDate2} from '../../utils/DateFunctions';
import {markAllAsReadApi} from '../../api/notificationsApi';
import {ROUTES} from '../../utils/ROUTES';
import {useMediaQuery} from '@material-ui/core';
import {user_type} from '../../utils/variables/userVariables';
import {Dashboard, LogoutOutlined, ManageSearch, PostAdd} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {Button, Drawer, Dropdown, Empty, List as Listy} from 'antd';
import {firebaseLogout} from '../../api/authApi';

export default function Navbar({style, backgroundColor = '#fffffe', boxShadow = 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'}) {
	const navigate = useNavigate();
	const [isOpen, setOpen] = useState(false);
	const [menu, setMenu] = useState(false);
	const [auth, setAuth] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const logo = FullLogo();
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [profileImage, setProfileImage] = useState('/userIcon.png');
	const [notifications, setNotifications] = useContext(NotificationContext);
	const [unreadNotifications, setUnreadNotifications] = useState();
	const isMobile = useMediaQuery('(max-width: 930px)');
	const URL_PATHNAME = window.location.pathname;

	const links = [
		{path: ROUTES.HOME, label: 'Home'},
		{path: ROUTES.BROWSE_JOBS, label: 'Browse jobs'},
		{path: ROUTES.ABOUT_US, label: 'About us'},
		{path: ROUTES.PRICING, label: 'Pricing'},
		{path: ROUTES.HOW_IT_WORKS, label: 'How it works'},
		{path: ROUTES.CONTACT_US, label: 'Contact us'},
		{path: ROUTES.POST_JOB, label: 'Post job'},
	];

	useState(() => {
		if (currentUser?.profile) {
			setUnreadNotifications(notifications?.filter((notif) => notif.read === false).length);
		}
	}, []);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		try {
			markAllAsReadApi();
			setUnreadNotifications(0);
		} catch (error) {
			return;
		}
	};

	useEffect(() => {
		if (currentUser?.profile) {
			setAuth(true);
			if (currentUser?.profile?.profile_image) {
				const img = new Image();
				img.src = currentUser.profile.profile_image;
				img.onload = () => setProfileImage(currentUser.profile.profile_image);
			}
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser?.profile) setAuth(true);
	}, [currentUser]);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				setNotifications([]);
				firebaseLogout();
				resolve();
			});
			setLoading(true);
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	const items = [
		{
			key: 'profile',
			label: (
				<div className="flex items-center gap-2">
					<img className="dummyIcon" src={profileImage} alt="Icon" style={{cursor: 'default', width: '30px', height: '30px'}} />
					<div>
						<p className=" max-w-[200px] truncate mb-0 text-sm " style={{color: permColors.darkCharcoal}}>
							{currentUser?.profile?.first_name ? `${currentUser?.profile?.first_name} ${currentUser?.profile?.last_name}` : currentUser?.email}
						</p>
						{currentUser?.profile?.user_type === user_type.JOB_SEEKER && (
							<p style={{color: permColors.charcoal}} className="mb-0 text-sm">
								{currentUser?.profile?.freelancer_profile_title}
							</p>
						)}
					</div>
				</div>
			),
			disabled: true,
			hover: false,
		},
		{
			key: 'view-profile',
			label: (
				<Button className="ButtonLinkBlue min-h-fit w-full m-0" onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
					View Profile
				</Button>
			),
			disabled: true,
		},
		{
			key: 'divider-1',
			type: 'divider',
		},
		{
			key: 'dashboard',
			label: 'Dashboard',
			icon: <Dashboard style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.DASHBOARD),
		},
		{
			key: 'eordashboard',
			label: 'EOR Dashboard',
			icon: <Dashboard style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.EOR_DASHBOARD),
		},
		currentUser?.profile?.user_type === user_type.EMPLOYER && {
			key: 'post-job',
			label: 'Post Job',
			icon: <PostAdd style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.POST_JOB),
		},
		{
			key: 'browse-jobs',
			label: 'Browse Jobs',
			icon: <ManageSearch style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.BROWSE_JOBS),
		},
		{
			key: 'divider-2',
			type: 'divider',
		},
		{
			key: 'logout',
			label: 'Logout',
			icon: <LogoutOutlined style={{fontSize: '1.2em'}} />,
			onClick: logout,
		},
	].filter(Boolean); // Filter out any falsy values from conditional rendering

	const guestLinks = () => {
		return (
			<>
				{!isMobile && (
					<div>
						<Button href={ROUTES.AUTHENTICATE}>Login</Button>
					</div>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{links.map(({path, label}) => (
							<Link key={path} className={(URL_PATHNAME === path ? 'bg-backgroundGrey w-full' : '') + ' text-sm p-2 rounded-md'} to={path}>
								{label}
							</Link>
						))}
						<Button type="default" href={ROUTES.AUTHENTICATE}>
							Login
						</Button>
					</div>
				)}
			</>
		);
	};

	const authLinks = () => {
		const authRoutes = [
			{path: ROUTES.EDIT_PROFILE, label: `Hello ${currentUser?.profile?.first_name || ''}!`},
			{path: ROUTES.HOME, label: 'Home'},
			{path: ROUTES.DASHBOARD, label: 'Dashboard'},
			{path: ROUTES.EOR_DASHBOARD, label: 'EOR Dashboard'},
			{path: ROUTES.BROWSE_JOBS, label: 'Jobs'},
			{path: ROUTES.POST_JOB, label: 'Post Job'},
			{path: '#', label: 'Logout', onClick: logout},
		];

		return (
			<Fragment>
				{!isMobile && (
					<Dropdown
						menu={{
							items,
						}}
						placement="bottomRight">
						<img className="dummyIcon" src={profileImage} alt="Icon" onClick={() => setDropdownVisible(!dropdownVisible)} />
					</Dropdown>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{authRoutes.map(({path, label, onClick}) => (
							<Link key={path} className={(URL_PATHNAME === path ? 'bg-backgroundGrey w-full' : '') + ' text-sm p-2 flex items-center gap-2 rounded-md'} to={path} onClick={onClick}>
								{path === ROUTES.EDIT_PROFILE && <img className="dummyIcon" src={profileImage} alt="Icon" />}
								{label}
							</Link>
						))}
					</div>
				)}
			</Fragment>
		);
	};

	return (
		<nav className="nav2" style={{backgroundColor: backgroundColor, boxShadow: boxShadow, ...style}}>
			<div>
				<div>
					<ul className="bigNav">
						<div className="inline2">
							<li>{logo}</li>
							<div className="flex items-end gap-4 ml-7 text-sm">
								{links.map(({path, label}) => (
									<li key={path}>
										<Link className={URL_PATHNAME === path ? 'font-bold' : ''} to={path}>
											{label}
										</Link>
									</li>
								))}
							</div>
						</div>
						<div className="inline text-sm gap-3">
							{auth && (
								<li>
									<Badge badgeContent={unreadNotifications} color="error">
										<NotificationsIcon onClick={handleShow} className="notificationsIcon" />
									</Badge>
								</li>
							)}
							<div className="right-links">{auth ? authLinks() : guestLinks()}</div>
						</div>
					</ul>
					<div className="smallNav">
						<span onClick={() => (menu ? setMenu(false) : setMenu(true))} className="flex items-center justify-between gap-2">
							<Hamburger size={26} toggled={isOpen} toggle={setOpen} className="hamburger2 w-[15px]" />
							<div>{logo}</div>
						</span>
						{auth && (
							<div className="rightSide">
								<Badge badgeContent={unreadNotifications} color="error">
									<NotificationsIcon onClick={handleShow} className="notificationsIcon" />
								</Badge>
							</div>
						)}
					</div>
					{menu && isMobile && (
						<div className="text-base text-left">
							<ul>{auth ? authLinks() : guestLinks()}</ul>
						</div>
					)}
				</div>
			</div>
			{loading && <Loading />}
			<Drawer title="Notifications" placement="right" onClose={handleClose} visible={show} width={300}>
				{notifications?.length > 0 ? (
					<Listy
						itemLayout="horizontal"
						dataSource={notifications}
						renderItem={(notif) => (
							<Listy.Item>
								<Listy.Item.Meta title={notif.message} description={formatDate2(notif.created_at)} />
							</Listy.Item>
						)}
					/>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications yet. Check back later." />
				)}
			</Drawer>
		</nav>
	);
}
