import {useEffect, useState} from 'react';
import './DashboardComponent.scss';
import {Link, useNavigate} from 'react-router-dom';
import {textFormatter, capitalizeFirstLetter} from '../../utils/TextAlterations';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Table, Button, Space, Popconfirm, Input, Select, Tooltip} from 'antd';
import {filterByJobType, filterBySearchTerm} from '../../utils/filters';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {ROUTES} from '../../utils/ROUTES';
import {deleteJobApi, getMyClosedJobsApi} from '../../api/jobsApi';
import {daysAgo, formatDate2} from '../../utils/DateFunctions';
import {closedJobsAtom, closedJobsTriggeredAtom} from '../../utils/atoms/dashboardAtoms';
import {useAtom} from 'jotai';

export default function ClosedJob() {
	const [closedJobs, setClosedJobs] = useAtom(closedJobsAtom);
	const [closedJobsTriggered, setClosedJobsTriggered] = useAtom(closedJobsTriggeredAtom);
	const [loading, setLoading] = useState(true);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');
	const navigate = useNavigate();

	useEffect(() => {
		if (!closedJobsTriggered) {
			fetchMyJobs();
		} else {
			setLoading(false);
		}
	}, []);

	const fetchMyJobs = async () => {
		setLoading(true);
		const response = await getMyClosedJobsApi();
		setLoading(false);
		if (response) {
			console.log('responsible', response);
			setClosedJobs(response);
		}
		setClosedJobsTriggered(true);
	};

	const navigatePostJob = () => {
		navigate(ROUTES.POST_JOB);
	};

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => <Link to={`/jobpage/${record.id}`}>{capitalizeFirstLetter(text)}</Link>,
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: capitalizeFirstLetter,
		},
		{
			title: 'Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Closed Date',
			dataIndex: 'closed_date',
			key: 'closed_date',
			render: (_, record) => (record.completed_at ? formatDate2(record.completed_at) : 'N/A'),
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => <div dangerouslySetInnerHTML={{__html: textFormatter(text)}} style={{maxHeight: '120px', overflowY: 'auto'}} />,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => navigate(`/applicants/${record.id}`)}>View Applicants</Button>
				</Space>
			),
		},
	];

	const filteredJobs = closedJobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			<div className="header">
				<div className="jobHeader">
					<h2>My Closed Jobs</h2>
				</div>
			</div>
			<div className="outerContainer">
				<div className="flex flex-wrap my-2 gap-2">
					<Input
						type="text"
						placeholder="Search by job title"
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						className="p-2 flex-grow flex-basis-[300px] max-w-full md:max-w-[300px]"
					/>
					<Select
						id="engagementTypeFilter"
						value={jobTypeFilter}
						onChange={(e) => setJobTypeFilter(e)}
						className="flex-grow flex-basis-[300px] max-w-full md:max-w-[300px]"
						options={[
							{label: 'All Job Types', value: 'all'},
							{label: 'Full Time', value: 'full-time'},
							{label: 'Part Time', value: 'part-time'},
						]}
					/>
					<Button className="h-[40px]" onClick={navigatePostJob}>
						Post a job
					</Button>
				</div>

				<div style={{height: '100%', overflowY: 'auto'}}>
					<Table dataSource={filteredJobs} columns={columns} pagination={{current: pageNumber, onChange: setPageNumber}} scroll={{x: true, y: 'calc(100vh - 300px)'}} />
				</div>
			</div>
		</div>
	);
}
