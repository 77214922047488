import React, {useEffect, useState} from 'react';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions} from '../../utils/arrayData/PortfolioArrays';
import {DeleteOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {editJobApi, getJobById} from '../../api/jobsApi';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Radio, Steps, Form, Input, Select, Button, List, Alert, Space, Typography, Layout, Modal, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import {permColors} from '../../utils/colors';
import Loading from '../../components/Loaders/Loading';
import {PostAdd} from '@mui/icons-material';
const {Title, Text} = Typography;
const {Content} = Layout;
export default function EditJobForm({jobId}) {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [loading, setLoading] = useState(true);
	const [fetchedJob, setFetchedJob] = useState(null);

	const [jobTitle, setJobTitle] = useState('');
	const [description, setDescription] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [country, setCountry] = useState('');
	const [jobType, setJobType] = useState('');
	const [category, setCategory] = useState('');
	const [engagementType, setEngagementType] = useState('');
	const [monthlySalary, setMonthlySalary] = useState(null);
	const [hourlyRate, setHourlyRate] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [coverLetterRequired, setCoverLetterRequired] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [currentStep, setCurrentStep] = useState(0);
	const [isApplying, setIsApplying] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const categories = categoryOptions;
	const countriesList = countries;

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const fetchJob = async () => {
		try {
			const res = await getJobById(jobId);
			setFetchedJob(res);

			// Populate form fields
			setJobTitle(res.title);
			setDescription(res.description);
			setCompanyName(res.company_name);
			setCountry(res.country);
			setCategory(res.category);
			setJobType(res.job_type);
			setEngagementType(res.engagement_type);
			setMonthlySalary(res.monthly_salary);
			setHourlyRate(res.hourly_rate);
			setQuestions(res.questions || []);
			setCoverLetterRequired(res.provide_cover_letter);

			// Set payment method based on available salary info
			setPaymentMethod(res.hourly_rate ? 'hourly' : 'fixed');

			form.setFieldsValue({
				jobTitle: res.title,
				companyName: res.company_name,
				description: res.description,
				country: res.country,
				category: res.category,
				jobType: res.job_type,
				engagementType: res.engagement_type,
				paymentMethod: res.hourly_rate ? 'hourly' : 'fixed',
				monthlySalary: res.monthly_salary,
				hourlyRate: res.hourly_rate,
			});
			console.log(res);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching job:', error);
			setLoading(false);
			setErrorMsg('Failed to load job details');
		}
	};

	useEffect(() => {
		fetchJob();
	}, [jobId]);

	const validateStep = async () => {
		try {
			await form.validateFields();
			return true;
		} catch (error) {
			return false;
		}
	};

	const handleNext = async () => {
		if (await validateStep()) {
			setCurrentStep(currentStep + 1);
		}
	};

	const handleBack = () => {
		setCurrentStep(currentStep - 1);
	};

	const addQuestion = () => {
		if (currentQuestion.trim()) {
			setQuestions([...questions, {question: currentQuestion}]);
			setCurrentQuestion('');
			console.log(questions);
		}
	};

	const removeQuestion = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
		console.log(questions);
	};

	const showPublishConfirm = () => {
		setIsModalVisible(true);
	};

	const handlePublish = async () => {
		try {
			await form.validateFields();
			setIsApplying(true);
			setIsModalVisible(false);

			const res = await editJobApi(
				jobId,
				jobTitle,
				description,
				companyName,
				country,
				jobType,
				category,
				engagementType,
				monthlySalary,
				null, // max monthly salary (not used in this version)
				hourlyRate,
				null, // max hourly rate (not used in this version)
				questions,
				coverLetterRequired
			);

			setIsApplying(false);

			if (res) {
				navigate(`/jobpage/${res.id}`);
			}
		} catch (error) {
			setErrorMsg('Please fill in all required fields');
			setIsApplying(false);
		}
	};

	const steps = [
		{
			title: 'Job Details',
			content: (
				<Form.Item>
					<Space direction="vertical" size="large" style={{width: '100%'}}>
						<Form.Item name="jobTitle" label="Title" rules={[{required: true, message: 'Please enter a job title'}]}>
							<Input placeholder="Virtual assistant" onChange={(e) => setJobTitle(e.target.value)} />
						</Form.Item>

						<Form.Item name="companyName" label="Company name" rules={[{required: true, message: 'Please enter a company name'}]}>
							<Input placeholder="Permworks" onChange={(e) => setCompanyName(e.target.value)} />
						</Form.Item>

						<Form.Item name="country" label="Company location" rules={[{required: true, message: 'Please select a country'}]}>
							<Select placeholder="Select country" onChange={(value) => setCountry(value)} showSearch>
								{countriesList.map((country, index) => (
									<Select.Option key={index} value={country}>
										{country}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item name="category" label="Category" rules={[{required: true, message: 'Please select a category'}]}>
							<Select placeholder="Select category" onChange={(value) => setCategory(value)}>
								{categories.map((category, index) => (
									<Select.Option key={index} value={category}>
										{category}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Space>
				</Form.Item>
			),
		},
		{
			title: 'Description',
			content: (
				<Form.Item
					name="description"
					label="Job Description"
					rules={[
						{
							required: true,
							validator: (_, value) => {
								// Remove HTML tags and trim whitespace
								const cleanContent = description.replace(/<[^>]*>/g, '').trim();
								if (!cleanContent) {
									return Promise.reject('Please enter a job description');
								}
								return Promise.resolve();
							},
						},
					]}>
					<CKEditor
						editor={ClassicEditor}
						config={{
							toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote'],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setDescription(data);
							form.setFieldValue('description', data); // Update form value
							form.validateFields(['description']); // Trigger validation
						}}
						data={description}
						minHeight={500}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Hiring Details',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item name="jobType" label="Employment Type" rules={[{required: true, message: 'Please select an employment type'}]}>
						<Radio.Group onChange={(e) => setJobType(e.target.value)} buttonStyle="solid">
							<Radio.Button value="Full-Time">Full-Time</Radio.Button>
							<Radio.Button value="Part-Time">Part-Time</Radio.Button>
							<Radio.Button value="One-time project">One-time project</Radio.Button>
						</Radio.Group>
					</Form.Item>

					<Form.Item name="engagementType" label="Engagement Type" rules={[{required: true, message: 'Please select an engagement type'}]}>
						<Radio.Group onChange={(e) => setEngagementType(e.target.value)} buttonStyle="solid">
							<Radio.Button value="Employee">Employee</Radio.Button>
							<Radio.Button value="Contractor">Contractor</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Space>
			),
		},
		{
			title: 'Salary',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Form.Item name="paymentMethod" label="Payment Type" rules={[{required: true, message: 'Please select a payment type'}]}>
						<Select onChange={(value) => setPaymentMethod(value)}>
							<Select.Option value="fixed">Pay a monthly fixed price</Select.Option>
							<Select.Option value="hourly">Pay by the hour</Select.Option>
						</Select>
					</Form.Item>

					{paymentMethod === 'fixed' && (
						<Form.Item name="monthlySalary" label="Monthly Salary (USD)" rules={[{required: true, message: 'Please enter a monthly salary'}]}>
							<Input type="number" onChange={(e) => setMonthlySalary(e.target.value)} />
						</Form.Item>
					)}

					{paymentMethod === 'hourly' && (
						<Form.Item name="hourlyRate" label="Hourly Rate (USD)" rules={[{required: true, message: 'Please enter an hourly rate'}]}>
							<Input type="number" onChange={(e) => setHourlyRate(e.target.value)} />
						</Form.Item>
					)}
				</Space>
			),
		},
		{
			title: 'Questions',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<Input.Group compact>
						<Input style={{width: 'calc(100% - 60px)'}} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} placeholder="Enter a question for candidates" />
						<Button type="primary" onClick={addQuestion}>
							Add
						</Button>
					</Input.Group>

					<List
						dataSource={questions}
						renderItem={(item, index) => (
							<List.Item actions={[<Button type="text" danger icon={<DeleteOutlined />} onClick={() => removeQuestion(index)} />]}>
								<Text>{item.question}</Text>
							</List.Item>
						)}
					/>
				</Space>
			),
		},
		{
			title: 'Review',
			content: (
				<Space direction="vertical" size="large" style={{width: '100%'}}>
					<List
						itemLayout="horizontal"
						dataSource={[
							{label: 'Title', value: jobTitle},
							{label: 'Company Name', value: companyName},
							{label: 'Country', value: country},
							{label: 'Category', value: category},
							{label: 'Job Type', value: jobType},
							{label: 'Engagement Type', value: engagementType},
							{label: 'Payment Method', value: paymentMethod === 'hourly' ? 'Hourly' : 'Fixed Price'},
							{
								label: paymentMethod === 'hourly' ? 'Hourly Rate' : 'Monthly Salary',
								value: hourlyRate || monthlySalary ? `$${paymentMethod === 'hourly' ? hourlyRate : monthlySalary} USD` : '',
							},
						]}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta title={item.label} description={item.value} />
							</List.Item>
						)}
					/>

					{questions.length > 0 && (
						<>
							<Title level={5}>Questions</Title>
							<List
								dataSource={questions}
								renderItem={(item, index) => (
									<List.Item>
										<Text strong>{`Question ${index + 1}: `}</Text>
										<Text>{item.question}</Text>
									</List.Item>
								)}
							/>
						</>
					)}
				</Space>
			),
		},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Content>
				<div className="flex justify-center p-8">
					<div className="w-full max-w-6xl">
						<Form form={form} layout="vertical" className="shadow-border rounded-lg p-2 bg-[#fafafa]">
							<div className="flex flex-col md:flex-row gap-6">
								<div className="w-full md:w-1/4 p-3">
									<div className="p-2 flex items-center gap-3 rounded-lg mb-4" style={{color: permColors.charcoal}}>
										<PostAdd style={{fontSize: '20px'}} />
										<h1 className="text-left text-lg m-0 font-medium" style={{color: permColors.charcoal}}>
											Edit Job Posting
										</h1>

										<Tooltip className="cursor-pointer" title="Fill out this form to create a new job posting">
											<InfoCircleOutlined style={{fontSize: '16px', background: permColors.backgroundLightCream}} className="p-1 rounded-lg" />
										</Tooltip>
									</div>
									<Steps direction={windowWidth <= 768 ? 'horizontal' : 'vertical'} current={currentStep} items={steps.map((step) => ({title: step.title}))} className="p-2" />
								</div>

								<div className="w-full md:w-3/4 shadow-border p-4 rounded-md flex flex-col justify-between bg-white">
									{steps[currentStep].content}

									<Space className="mt-4">
										{currentStep > 0 && <Button onClick={handleBack}>Back</Button>}

										{currentStep < steps.length - 1 && (
											<Button type="primary" onClick={handleNext}>
												Next
											</Button>
										)}

										{currentStep === steps.length - 1 && (
											<Button type="primary" onClick={showPublishConfirm} loading={isApplying}>
												Publish
											</Button>
										)}
									</Space>

									{errorMsg && <Alert style={{marginTop: '1rem'}} message={errorMsg} type="error" showIcon />}
								</div>
							</div>
						</Form>
					</div>
				</div>
			</Content>

			<Modal title="Confirm Publication" open={isModalVisible} onOk={handlePublish} onCancel={() => setIsModalVisible(false)} okText="Yes, Publish" cancelText="Cancel">
				<p>Are you sure you want to publish your changes?</p>
			</Modal>
		</>
	);
}
