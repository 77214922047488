import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Typography, Divider, Tag, Space, Collapse, Alert} from 'antd';
import {Helmet} from 'react-helmet';
import {capitalize} from '@material-ui/core';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {UserContext} from '../../utils/UserContext';
import './JobPage.scss';
import Loading from '../../components/Loaders/Loading';
import {getJobById} from '../../api/jobsApi';
import {textFormatter} from '../../utils/TextAlterations';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {permColors, tailwindPermColors} from '../../utils/colors';
import {WorkOutlined} from '@mui/icons-material';
import {daysAgo} from '../../utils/DateFunctions';
const {Text, Paragraph} = Typography;
const {Panel} = Collapse;

export default function JobPage() {
	const [job, setJob] = useState({});
	const {jobId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchJob() {
			const jobData = await getJobById(jobId);
			setJob(jobData);
			setLoading(false);
		}
		fetchJob();
	}, [currentUser]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div style={{backgroundColor: permColors.backgroundGreyBlue}}>
			<Helmet>
				<title>{`${job?.title ? job?.title : 'Job'} | Permworks`}</title>
				<meta name="description" content={`Apply for ${job?.title ? job?.title : 'Job'} at Permworks. Join our global talent network.`} />
			</Helmet>
			<Navbar />
			<div className="py-8 px-4 sm:px-6 lg:px-8">
				<Card className="max-w-4xl mx-auto">
					<div className="flex flex-col gap-1">
						<div className="flex items-center justify-between gap-2 mb-4">
							<div className="rounded-full border border-gray-200 p-2 w-fit">
								{job?.created_by_image ? <img src={job?.created_by_image} alt="profile" className="working-icon p-0" /> : <WorkOutlined className="working-icon" />}
							</div>
							<div className="flex items-center gap-2 flex-wrap">
								<span className="border border-gray-300 text-gray-500 rounded-md p-2 text-sm flex items-center gap-2">
									{job?.job_type}
									<WorkOutlined style={{fontSize: '18px'}} />
								</span>
								<ApplyJobButton job={job} />
							</div>
						</div>

						<p className="text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
							{job?.created_by_company_name ? capitalize(job?.created_by_company_name) : capitalize(job?.company_name)}
							<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
								{job?.created_at !== null ? `Posted on the ${daysAgo(job?.created_at)} days ago` : ''}
							</span>
						</p>

						<span className="text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
							{job?.title && capitalize(job?.title)}
						</span>

						<div className="flex items-center gap-2">
							<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
								{job?.engagement_type}
							</div>
							<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
								{job?.category}
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-1 mt-2">
						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${parseFloat(job.max_monthly_salary).toString()}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${parseFloat(job.max_hourly_rate).toString()}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}

						<p className="text-sm font-semibold opacity-90" style={{color: permColors.charcoal}}>
							{job.country}
						</p>
					</div>

					<Divider />
					<p className="text-base font-semibold underline underline-offset-2" style={{color: permColors.darkCharcoal}}>
						Job Description
					</p>

					{job?.description && (
						<Paragraph className={tailwindPermColors.charcoal}>
							<div dangerouslySetInnerHTML={{__html: textFormatter(job.description)}} />
						</Paragraph>
					)}

					<Divider />

					{job?.questions?.length > 0 ? (
						<Collapse>
							<Panel header={job?.questions.length > 1 ? 'Application Questions' : 'Application question'} key="1">
								<Space direction="vertical" size="small">
									{job.questions?.map((q, index) => (
										<Text key={index}>
											{index + 1}. {capitalize(q?.question)}
										</Text>
									))}
								</Space>
							</Panel>
						</Collapse>
					) : (
						<Alert message="No required questions to answer for this job" className="rounded-md" type="info" showIcon banner />
					)}
				</Card>
			</div>
			<Footer backgroundColor={permColors.backgroundLightCream} />
		</div>
	);
}
