import Cookies from 'js-cookie';
import {COOKIES} from './cookie-names';

export const saveSearch = (search: string) => {
	if (!search) return;
	const existingSearches = Cookies.get(COOKIES.search);
	let searches = [];

	if (existingSearches) {
		searches = JSON.parse(existingSearches);
	}

	if (!searches.includes(search)) {
		searches.push(search);
	}

	Cookies.set(COOKIES.search, JSON.stringify(searches));
};

export const getSearchHistory = () => {
	return Cookies.get(COOKIES.search);
};

export const clearSearchItem = (search: string) => {
	if (!search) return;
	const existingSearches = Cookies.get(COOKIES.search);
	let searches = [];

	if (existingSearches) {
		searches = JSON.parse(existingSearches);
	}

	searches = searches.filter((s) => s !== search);

	Cookies.set(COOKIES.search, JSON.stringify(searches));
};
