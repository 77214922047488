import React from 'react';
import {Layout} from 'antd';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbars/Navbar2';
import {permColors} from '../../utils/colors';
import Footer from '../../components/Footer/Footer';
import EditJobForm from './EditJobForm';
import {useParams} from 'react-router-dom';

export default function EditJobPage() {
	const {jobId} = useParams();

	return (
		<Layout style={{background: permColors.white}}>
			<Helmet>
				<title>Edit Job | Permworks</title>
				<meta name="description" content="Edit and update your job posting with detailed information." />
			</Helmet>

			<Navbar />
			<EditJobForm jobId={jobId} />
			<Footer />
		</Layout>
	);
}
