import {permColors} from '../../utils/colors';
import {Tabs} from 'antd';

export default function TabSelector({value, handleChange}) {
	const items = [
		{
			key: '0',
			label: 'CV & questions',
		},
		{
			key: '1',
			label: 'Work experience',
		},
		{
			key: '2',
			label: 'Education',
		},
	];

	return (
		<Tabs
			activeKey={value.toString()}
			onChange={(key) => handleChange(null, parseInt(key))}
			items={items}
			className="overflow-x-auto max-w-[500px] lg:px-5"
			tabBarStyle={{
				borderBottom: `1px solid ${permColors.backgroundGrey}`,
				color: permColors.charcoal,
			}}
		/>
	);
}
