import './LandingPage.scss';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import Card from 'react-bootstrap/Card';
import {Modal} from 'antd';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BusinessIcon from '@mui/icons-material/Business';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {Place} from '@material-ui/icons';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {permColors} from '../../utils/colors';

interface SelectedJobCardProps {
	job: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}
export function SelectedJobCard({job, onNavigate, authenticatedButtons}: SelectedJobCardProps) {
	return (
		<div className="selectedFeed hidden md:block">
			<Card className=" rounded-lg p-4 flex flex-col justify-between gap-1" style={{height: '95vh'}}>
				<div className="flex items-top justify-between gap-2 mb-4">
					<div className="rounded-full border border-gray-200 p-2 w-fit h-fit">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					</div>
					<div className="flex flex-col items-end gap-2">
						{authenticatedButtons(job)}
						<ApplyJobButton job={job} />
					</div>
				</div>

				<p className="text-base font-semibold flex items-center gap-3" style={{color: permColors.charcoal}}>
					{job?.created_by_company_name ? capitalizeFirstLetter(job.created_by_company_name) : capitalizeFirstLetter(job?.company_name)}
					<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</span>
				</p>

				<span className="text-2xl font-medium mb-2 flex items-center gap-2" style={{color: permColors.darkCharcoal}}>
					{capitalizeFirstLetter(job.title)}
					<ApplyJobButton job={job} />
				</span>

				<div className="flex items-center gap-2 ">
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.engagement_type}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.category}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.job_type}
					</div>
				</div>

				<hr className="mt-4 mb-2" />

				<div className="flex items-center justify-between">
					<div>
						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${job.max_monthly_salary}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${job.max_hourly_rate}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}
						<p className="text-base font-semibold opacity-80" style={{color: permColors.charcoal}}>
							{job.country}
						</p>
					</div>
				</div>

				<div
					className="description grow mt-4"
					dangerouslySetInnerHTML={{
						__html: textFormatter(job.description),
					}}
				/>
			</Card>
		</div>
	);
}

interface JobModalProps {
	job: any;
	show: boolean;
	onHide: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}

export function JobModal({job, show, onHide, onNavigate, authenticatedButtons}: JobModalProps) {
	return (
		<Modal open={show} onCancel={onHide} style={{height: '95vh'}} footer={null} width={700} centered>
			<div className="p-0 pt-4 flex flex-col justify-between gap-1">
				<div className="flex items-center justify-between gap-2 mb-4">
					<div className="rounded-full border border-gray-200 p-2 w-fit">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					</div>
					{authenticatedButtons(job)}
				</div>

				<p className="text-base font-semibold flex items-center gap-3" style={{color: permColors.charcoal}}>
					{job?.created_by_company_name ? capitalizeFirstLetter(job.created_by_company_name) : capitalizeFirstLetter(job?.company_name)}
					<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</span>
				</p>

				<span className="text-2xl font-medium mb-2 flex items-center gap-2" style={{color: permColors.darkCharcoal}}>
					{capitalizeFirstLetter(job.title)} <ApplyJobButton job={job} />
				</span>

				<div className="flex items-center gap-2">
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.engagement_type}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.category}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.job_type}
					</div>
				</div>

				<hr className="mt-4 mb-2" />

				<div className="flex items-center justify-between">
					<div>
						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${job.max_monthly_salary}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${job.max_hourly_rate}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}
						<p className="text-base font-semibold opacity-80" style={{color: permColors.charcoal}}>
							{job.country}
						</p>
					</div>
				</div>

				<div className="mt-4">
					<div className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
						Description
					</div>
					<hr className="my-2 opacity-10" />
					<div
						dangerouslySetInnerHTML={{
							__html: textFormatter(job.description),
						}}
					/>
				</div>
			</div>
		</Modal>
	);
}
