import React from 'react';
import './LandingPage.scss';
import {capitalizeFirstLetter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {WorkOutlined} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {Button} from 'antd';

interface JobCardProps {
	job: any;
	isSelected: boolean;
	onJobSelect: any;
}

export function JobCard({job, isSelected, onJobSelect}: JobCardProps) {
	return (
		<React.Fragment>
			<div
				onClick={() => onJobSelect(job)}
				className={`cardStyle cursor-pointer text-left p-4 rounded-lg flex flex-col justify-between gap-1 md:w-[500px] max-w-full ${isSelected ? 'selectedAllCards' : ''}`}>
				<div className="flex items-center justify-between gap-2 mb-4">
					<div className="rounded-full border border-gray-200 p-2 w-fit">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon " /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					</div>
					<span className="border border-gray-300 text-gray-500 rounded-md p-2 text-sm flex items-center gap-2">
						{job.job_type}
						<WorkOutlined style={{fontSize: '18px'}} />
					</span>
				</div>
				<p className="text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
					{job?.created_by_company_name ? capitalizeFirstLetter(job.created_by_company_name) : capitalizeFirstLetter(job?.company_name)}
					<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</span>
				</p>
				<span className="text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
					{capitalizeFirstLetter(job.title)}
				</span>
				<div className="flex items-center gap-2">
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.engagement_type}
					</div>
					<div className="p-2 rounded-md w-fit text-sm" style={{color: permColors.charcoal, backgroundColor: 'rgb(229 231 235)'}}>
						{job.category}
					</div>
				</div>
				<hr className="mt-4 mb-2" />
				<div className="flex items-center justify-between">
					<div>
						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${parseFloat(job.max_monthly_salary).toString()}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${parseFloat(job.max_hourly_rate).toString()}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}

						<p className="text-base font-semibold opacity-80" style={{color: permColors.charcoal}}>
							{job.country}
						</p>
					</div>
					<Button
						className="p-3"
						onClick={() => onJobSelect(job)}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
								/>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
							</svg>
						}
						style={{color: permColors.grey}}></Button>
				</div>
			</div>
			<br />
		</React.Fragment>
	);
}
