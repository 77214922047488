import {QuestionMark, ThumbDown, ThumbUp} from '@mui/icons-material';
import {Alert} from '@mui/material';
import {Alert as AntAlert} from 'antd';

const fitMappings = {
	Good_fit: {
		message: 'Good fit',
		severity: 'success',
	},
	Not_a_fit: {
		message: 'Not a fit',
		severity: 'error',
	},
	default: {
		message: 'No decision provided',
		severity: 'info',
	},
};

export function returnApplicantFit(candidateFit) {
	const {message, severity} = fitMappings[candidateFit] || fitMappings.default;
	return <AntAlert banner type={severity} className="flex p-1 px-2 text-sm font-normal rounded-md mt-1" message={message} showIcon />;
}

export function returnApplicantFitSmall(candidateFit) {
	const {message, severity} = fitMappings[candidateFit] || fitMappings.default;
	return <AntAlert banner type={severity} className="flex p-1 px-2 text-xs rounded-md mt-1 whitespace-nowrap" message={message} showIcon />;
}

export function returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, loading) {
	const noOp = () => {};
	const handleClick = loading ? noOp : submitCandidateFitChange;

	return (
		<div className={`flex items-center thumb-group w-fit ${loading ? 'loading' : ''}`}>
			<div
				onClick={() => handleClick('Good_fit')}
				className={`p-2 px-3 thumb thumb-1 ${selectedApplicant.candidate_fit === 'Good_fit' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<ThumbUp />
			</div>
			<div
				onClick={() => handleClick('Undecided')}
				className={`p-2 px-3 thumb thumb-2 ${selectedApplicant.candidate_fit === 'Undecided' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<QuestionMark />
			</div>
			<div
				onClick={() => handleClick('Not_a_fit')}
				className={`p-2 px-3 thumb thumb-3 ${selectedApplicant.candidate_fit === 'Not_a_fit' ? 'thumb-selected' : ''} ${loading ? 'thumb-disabled' : ''}`}>
				<ThumbDown />
			</div>
		</div>
	);
}
