import {List, Table, Typography} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import PdfViewer from './PdfViewer';
import {textFormatter} from '../../utils/TextAlterations';
import {Alert} from 'antd';
const {Column} = Table;

export default function ApplicantComponent({value, selectedApplicant}) {
	const renderWorkExperience = () => (
		<>
			{selectedApplicant.freelancer.work_experience.length > 0 ? (
				<Table dataSource={selectedApplicant.freelancer.work_experience} rowKey="id" pagination={false} scroll={{x: 500}}>
					<Column title="Position" dataIndex="position_title" />
					<Column title="Company" dataIndex="company_name" />
					<Column title="From" render={(_, record) => `${record.from_month || ''} ${record.from_year || ''}`} />
					<Column title="To" render={(_, record) => `${record.to_month || ''} ${record.to_year || 'Present'}`} />
				</Table>
			) : (
				<Alert message="No work experiences provided." type="info" banner />
			)}
		</>
	);

	const renderEducation = () => (
		<>
			{selectedApplicant.freelancer.education.length > 0 ? (
				<Table dataSource={selectedApplicant.freelancer.education} rowKey="id" pagination={false} scroll={{x: 500}}>
					<Column title="Degree" dataIndex="education_qualification" />
					<Column title="Field of Study" dataIndex="area_of_study" />
					<Column title="School" dataIndex="school_name" />
					<Column title="From Year" dataIndex="year_from" />
					<Column title="To Year" render={(year_to) => (year_to ? year_to : 'Present')} />
				</Table>
			) : (
				<Alert message="No education information provided." type="info" banner />
			)}
		</>
	);

	const renderApplication = () => (
		<>
			{selectedApplicant?.answers?.length > 0 && (
				<>
					<Typography.Title level={5} className="mb-2">
						Screening questions
					</Typography.Title>
					<List
						dataSource={selectedApplicant.answers}
						renderItem={(answer) => (
							<List.Item>
								<List.Item.Meta title={answer.question.question} description={answer.answer} />
							</List.Item>
						)}
					/>
				</>
			)}

			{selectedApplicant?.attachment && (
				<div className="my-4">
					{selectedApplicant?.answers?.length > 0 && <div className="my-4 border-t" />}
					<a href={selectedApplicant.attachment} download target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 text-blue-600 hover:text-blue-800">
						<DownloadOutlined /> Download CV
					</a>
					<div className="mt-4">
						<PdfViewer fileName={selectedApplicant.attachment} />
					</div>
				</div>
			)}

			{selectedApplicant?.cover_letter_or_message && (
				<div className="my-4">
					<div className="border-t my-4" />
					<Typography.Title level={5}>Cover letter</Typography.Title>
					<div className="mt-2" style={{color: permColors.charcoal}} dangerouslySetInnerHTML={{__html: textFormatter(selectedApplicant.cover_letter_or_message)}} />
					<div className="border-t my-4" />
				</div>
			)}
		</>
	);

	return (
		<div className="lg:p-4">
			{value === 0 && renderApplication()}
			{value === 1 && renderWorkExperience()}
			{value === 2 && renderEducation()}
		</div>
	);
}
